import * as Yup from "yup";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SHA256 } from "crypto-js";
import { stringValidation } from "../providers/validations";
import Field from "../components/Field";
import { Select, Text } from "../inputs";
import { Form, FieldArray } from "../template";
import {
  MapContainer,
  TileLayer,
  useMap,
  Popup,
  Marker,
  useMapEvents,
} from "react-leaflet";

const UserForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [position, setPosition] = useState(null);
  const LocationMarker = () => {
    const map = useMapEvents({
      click(e) {
        setPosition(e?.latlng);
        map.flyTo(e?.latlng, map.getZoom());
      },
    });

    return position === null ? null : (
      <Marker position={position}>
        <Popup>Цэгийн байршил</Popup>
      </Marker>
    );
  };
  const props = {
    editable: true,
    isDeletable: true,
    model: "device",
    text: id ? "Төхөөрөмжийн мэдээлэл" : "Төхөөрөмж бүртгэх",
    id: id,
    init: {},
    setPosition: setPosition,
    validationSchema: Yup.object().shape({
      // host_id: stringValidation(true),
      // host_name: stringValidation(true),
      // name: stringValidation(true),
      // antine: stringValidation(true),
      // direction: stringValidation(true),
    }),
  };
  const beforeSubmit = (data) => {
    return {
      ...data,
      coordinates: {
        lat: position ? position?.lat : 0,
        lng: position ? position?.lng : 0,
      },
    };
  };
  return (
    <Form {...props} beforeSubmit={beforeSubmit}>
      {({ submited, disabled, values }) => {
        return (
          <div className="p-5 bg-white  grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex flex-col gap-4">
              <Field
                name="host_id"
                label={`Хост ID`}
                disabled={disabled}
                component={Text}
                type="text"
                className={`text-black `}
              />
              <Field
                name="host_name"
                label={`Хост нэр`}
                disabled={disabled}
                component={Text}
                type="text"
                className={`text-black `}
              />
              <Field
                name="name"
                label={`Нэр`}
                disabled={disabled}
                component={Text}
                type="text"
                className={`text-black `}
              />
              <Field
                name="antine"
                label={`Антине`}
                disabled={disabled}
                component={Select}
                items={[
                  {
                    label: `1`,
                    value: `1`,
                  },
                  {
                    label: `2`,
                    value: `2`,
                  },
                  {
                    label: `3`,
                    value: `3`,
                  },
                  {
                    label: `4`,
                    value: `4`,
                  },
                ]}
                type="text"
              />
              <Field
                name="lane"
                label={`Эгнээ`}
                disabled={disabled}
                component={Select}
                items={[
                  {
                    label: `1`,
                    value: `1`,
                  },
                  {
                    label: `2`,
                    value: `2`,
                  },
                  {
                    label: `3`,
                    value: `3`,
                  },
                  {
                    label: `4`,
                    value: `4`,
                  },
                  {
                    label: `5`,
                    value: `5`,
                  },
                  {
                    label: `6`,
                    value: `6`,
                  },
                ]}
                type="text"
              />
              <Field
                name="direction"
                label={`Чиглэл`}
                disabled={disabled}
                component={Select}
                items={[
                  { label: `Зүүнээс-Баруун`, value: `Зүүнээс-Баруун` },
                  { label: `Баруунаас-Зүүн`, value: `Баруунаас-Зүүн` },
                  { label: `Хойноос-Урагш`, value: `Хойноос-Урагш` },
                  { label: `Урдаас-Хойш`, value: `Урдаас-Хойш` },
                  { label: `Орох`, value: `Орох` },
                  { label: `Гарах`, value: `Гарах` },
                ]}
                type="text"
              />
              <div>
                <label className="label order-1">{"Байршил"}</label>
                <Text
                  className="text-blue-500"
                  value={
                    position
                      ? position?.lat + " , " + position?.lng
                      : "Газрын зураг дээр цэглэх"
                  }
                />
              </div>
              <MapContainer
                center={[47.919108, 106.91752]}
                zoom={12}
                style={{ width: 500, height: 500 }}
                scrollWheelZoom={true}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker />
              </MapContainer>
            </div>
            <div className="flex flex-col gap-4">
              <FieldArray
                name="speed"
                label="Хурд хэмжилт"
                disabled={disabled}
                initial={{
                  device: "",
                  avarage: "",
                }}
                duplicatable={true}
                renderItem={(row, qIndex) => (
                  <div className="flex flex-col">
                    <div className="w-full">
                      <Field
                        name={`speed.${qIndex}.device`}
                        label="Төхөөрөмж"
                        disabled={disabled}
                        component={Select}
                        className={"flex"}
                        type="text"
                        axio={`/device/list`}
                        axioAdapter={(data) => {
                          return data.map(({ _id, name, direction }) => ({
                            value: _id,
                            label: name + " " + direction,
                          }));
                        }}
                      />
                    </div>
                    <div className="flex w-full flex-row">
                      <div className="w-1/2 ml-1">
                        <Field
                          name={`speed.${qIndex}.range`}
                          label="Зай"
                          disabled={disabled}
                          component={Text}
                          className={"flex h-16"}
                          type="text"
                          placeholder={`100км жишээ`}
                        />
                      </div>
                      <div className="w-1/2 ml-1">
                        <Field
                          name={`speed.${qIndex}.avarage`}
                          label="Зөвшөөрөгдөх хурд"
                          disabled={disabled}
                          component={Text}
                          className={"flex h-16"}
                          type="text"
                          placeholder={`км/ц`}
                        />
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        );
      }}
    </Form>
  );
};

export default UserForm;
