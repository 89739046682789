import objectPath from "object-path";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
const colors = {
  rfid: "#6236F5",
  police: "#FF3A29",
  citylrp: "#FFB200",
  tollgate: "#02A0FC",
  traffic: "#0f0",
  no_violation: "rgba(28, 28, 28, 0.2)",
};
const getPercentage = (total, value) => {
  return ((value / total) * 100).toFixed(1);
};
const fixData = (arr, val) => {
  const temp = [];
  const result_ub = [];
  const result = [];
  let total = 0;
  let all = 0;
  const temp_ub = [];
  let ub_total = 0;
  let ub_all = 0;
  arr?.map((row) => {
    if (objectPath.get(row, "location") == "UB") {
      temp_ub.push({
        tax: objectPath.get(row, `${val}`),
        color: objectPath.get(colors, `${row?.from}`),
      });
      ub_total += objectPath.get(row, `no${val}`);
      ub_all += objectPath.get(row, `no${val}`);
      ub_all += objectPath.get(row, `${val}`);
    } else {
      temp.push({
        tax: objectPath.get(row, `${val}`),
        color: objectPath.get(colors, `${row?.from}`),
      });
      total += objectPath.get(row, `no${val}`);
      all += objectPath.get(row, `no${val}`);
      all += objectPath.get(row, `${val}`);
    }
  });
  temp.push({
    tax: total,
    color: colors.no_violation,
  });
  temp_ub.push({
    tax: ub_total,
    color: colors.no_violation,
  });

  temp_ub.map((row) => {
    result_ub.push({
      ...row,
      percent: getPercentage(ub_all, row.tax),
    });
  });
  temp.map((row) => {
    result.push({
      ...row,
      percent: getPercentage(all, row.tax),
    });
  });
  return {
    ub: result_ub,
    country: result,
  };
};

const Row = ({ name, val, arr }) => {
  const [loading, setloading] = useState();
  const [data, setdata] = useState();
  useEffect(() => {
    setloading(true);
    const result = fixData(arr, val);

    setdata(result);
    setloading(false);
  }, []);

  //   const data = fixData(arr, val);

  return (
    <div className="flex w-full justify-between items-center mt-6 h-10">
      <div className="flex justify-center items-center h-10 w-32">
        <div className="flex text-base text-black text-center text-black  font-normal">
          {name}
        </div>
      </div>
      {loading ? (
        <div className="flex w-full h-80 justify-center items-center bg-white">
          <Loader size={50} />
        </div>
      ) : (
        <div className="flex w-full flex-col items-center">
          <div className="flex w-full flex-row px-2">
            {data?.ub?.map((r, i) => {
              const percent = objectPath.get(r, "percent");
              const color = objectPath.get(r, "color");

              return (
                <div
                  className={`flex justify-center text-center shadow min-w-10 text-black  items-center text-xs ${
                    i == 0 ? "rounded-l-lg" : i == 3 && "rounded-r-lg"
                  }`}
                  style={{
                    width: `${percent}%`,
                    backgroundColor: color,
                    height: 16,
                  }}
                >
                  {percent}
                </div>
              );
            })}
          </div>
          <div className="flex w-full flex-row px-2 mt-2">
            {data?.country?.map((r, i) => {
              const percent = objectPath.get(r, "percent");
              const color = objectPath.get(r, "color");

              return (
                <div
                  className={`flex justify-center text-center text-black  shadow min-w-10 items-center text-xs ${
                    i == 0 ? "rounded-l-lg" : i == 3 && "rounded-r-lg"
                  }`}
                  style={{
                    width: `${percent}%`,
                    backgroundColor: color,
                    height: 16,
                  }}
                >
                  {percent}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="flex flex-col h-10 min-w-20">
        <div className="text-sm text-black font-normal  -mt-0.5">Нийслэл</div>
        <div className="text-sm text-black font-normal   mt-1">Орон нутаг</div>
      </div>
    </div>
  );
};
const StackedBar = ({ data }) => {
  const rfid = objectPath.get(data, "rfid") ? objectPath.get(data, "rfid") : [];
  const police = objectPath.get(data, "police")
    ? objectPath.get(data, "police")
    : [];
  const tollgate = objectPath.get(data, "tollgate")
    ? objectPath.get(data, "tollgate")
    : [];
  const citylrp = objectPath.get(data, "citylrp")
    ? objectPath.get(data, "citylrp")
    : [];
  const traffic = objectPath.get(data, "traffic")
    ? objectPath.get(data, "traffic")
    : [];
  const arr = [...rfid, ...police, ...tollgate, ...citylrp, ...traffic];

  return (
    <div className="flex flex-col w-full">
      <Row name={"Татвар"} val="tax" arr={arr} />
      <Row name={"Даатгал"} val="insurance" arr={arr} />
      <Row name={"Оношилгоо"} val="inspection" arr={arr} />
      <Row name={"Торгууль"} val="penalty" arr={arr} />
      <Row name={"Эрэн сурвалжлалт"} val="asap" arr={arr} />
      <Row name={"Жолоодох эрхгүй"} val="drunk" arr={arr} />
      <Row name={"Дугаарын хязгаарлалт"} val="limit" arr={arr} />
      <div className="flex w-full justify-center items-center mt-4">
        <div className="flex">
          <div className="flex items-center mx-3">
            <div
              style={{ backgroundColor: colors.rfid }}
              className="h-2 w-2 rounded-full"
            />
            <div className="ml-2 text-base font-normal text-black ">RFID</div>
          </div>
          <div className="flex items-center mx-3">
            <div
              style={{ backgroundColor: colors.police }}
              className="h-2 w-2 rounded-full"
            />
            <div className="ml-2 text-base font-normal text-black  ">ТЦА</div>
          </div>
          <div className="flex items-center mx-3">
            <div
              style={{ backgroundColor: colors.citylrp }}
              className="h-2 w-2 rounded-full"
            />
            <div className="ml-2 text-base font-normal text-black  ">
              CityLRP
            </div>
          </div>
          <div className="flex items-center mx-3">
            <div
              style={{ backgroundColor: colors.tollgate }}
              className="h-2 w-2 rounded-full"
            />
            <div className="ml-2 text-base font-normal text-black  ">ТАЦ</div>
          </div>
          <div className="flex items-center mx-3">
            <div
              style={{ backgroundColor: colors.traffic }}
              className="h-2 w-2 rounded-full"
            />
            <div className="ml-2 text-base font-normal text-black  ">ЗХУТ</div>
          </div>
          <div className="flex items-center mx-3">
            <div
              style={{ backgroundColor: colors.no_violation }}
              className="h-2 w-2 rounded-full"
            />
            <div className="ml-2 text-base font-normal text-black  ">
              Зөрчилгүй
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StackedBar;
