import { IoMdPersonAdd } from "react-icons/io";
import { Link } from "react-router-dom";
import Table from "../list/expand";
import moment from "moment";
import objectPath from "object-path";
const check = (val) => {
  return val ? "Зөрчилтэй" : "Зөрчилгүй";
};
const index = () => {
  return (
    <div className="flex flex-col ">
      <div className="flex items-center w-full h-20 py-3 px-8">
        <div className="text-xl font-semibold leading-5 ">
          Тээврийн хэрэгсэл
        </div>
      </div>
      <div className="px-4">
        <Table
          url={`/car/table`}
          excelUrl={`/car/excel`}
          sort={{ _id: -1 }}
          columns={[
            {
              label: `Улсын №`,
              key: `plate`,
              sort: true,
              filter: {
                type: "text",
                noregex: true,
              },
              render: ({ _id, plate }) => {
                return (
                  <div className="text-odt-primary font-medium text-center">
                    {plate}
                  </div>
                );
              },
            },
            {
              label: `Зураг`,
              key: `photo`,
              sort: true,
              center: true,
              render: ({ _id, photo, from }) => {
                const url = `https://images.weserv.nl/?url=${photo}`;
                return (
                  <img
                    src={photo ? photo : "../images/rfid.png"}
                    className="h-16 object-contain justify-center items-center self-center"
                    loading="lazy"
                    onError={(e) => {
                      e.target.onerror = null; // Prevents looping
                      e.target.src = "/noimage.jpg";
                    }}
                  />
                );
              },
            },
            {
              label: `Марк`,
              key: `mark`,
              sort: true,
              center: true,
              filter: {
                type: "text",
              },
            },
            {
              label: `Систем`,
              key: `from`,
              center: true,
              sort: true,
              filter: {
                type: "text",
              },
            },
            {
              label: `Модел`,
              key: `model`,
              center: true,
              sort: true,
              filter: {
                type: "text",
              },
            },

            {
              label: `Зөрчилтэй`,
              key: `isViolation`,
              sort: true,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, isViolation, modified, created }) => {
                return (
                  <div className="flex flex-col justify-center items-center">
                    <div
                      className={`font-semibold  ${
                        isViolation ? "text-red-500" : "text-green-500"
                      }`}
                    >
                      {check(isViolation)}
                    </div>
                    <div className="text-xs font-semibold text-center">
                      {modified
                        ? moment(modified).format("yyyy-MM-DD HH:mm")
                        : moment(created).format("yyyy-MM-DD HH:mm")}
                    </div>
                  </div>
                );
              },
            },
            {
              label: `Хязгаарлалт`,
              key: `limit`,
              sort: true,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, limit, limitDate }) => {
                return (
                  <div className="flex flex-col justify-center items-center">
                    <div
                      className={`font-semibold  ${
                        limit ? "text-red-500" : "text-green-500"
                      }`}
                    >
                      {check(limit)}
                    </div>
                    <div className="text-xs font-semibold text-center">
                      {moment(limitDate).format("yyyy-MM-DD HH:mm")}
                    </div>
                  </div>
                );
              },
            },
            {
              label: `Татвар`,
              key: `tax`,
              sort: true,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, tax, taxDate }) => {
                return (
                  <div className="flex flex-col justify-center items-center">
                    <div
                      className={`font-semibold  ${
                        tax ? "text-red-500" : "text-green-500"
                      }`}
                    >
                      {check(tax)}
                    </div>
                    <div className="text-xs font-semibold text-center">
                      {moment(taxDate).format("yyyy-MM-DD HH:mm")}
                    </div>
                  </div>
                );
              },
            },
            {
              label: `Даатгал`,
              key: `insurance`,
              sort: true,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, insurance, insuranceDate }) => {
                return (
                  <div className="flex flex-col justify-center items-center">
                    <div
                      className={`font-semibold  ${
                        insurance ? "text-red-500" : "text-green-500"
                      }`}
                    >
                      {check(insurance)}
                    </div>
                    <div className="text-xs font-semibold text-center">
                      {moment(insuranceDate).format("yyyy-MM-DD HH:mm")}
                    </div>
                  </div>
                );
              },
            },
            {
              label: `Оншилгоо`,
              key: `inspection`,
              sort: true,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, inspection, inspectionDate }) => {
                return (
                  <div className="flex flex-col justify-center items-center">
                    <div
                      className={`font-semibold  ${
                        inspection ? "text-red-500" : "text-green-500"
                      }`}
                    >
                      {check(inspection)}
                    </div>
                    <div className="text-xs font-semibold text-center">
                      {moment(inspectionDate).format("yyyy-MM-DD HH:mm")}
                    </div>
                  </div>
                );
              },
            },
            {
              label: `Торгууль`,
              key: `penalty`,
              sort: true,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, penalty, penaltyDate }) => {
                return (
                  <div className="flex flex-col justify-center items-center">
                    <div
                      className={`font-semibold  ${
                        penalty ? "text-red-500" : "text-green-500"
                      }`}
                    >
                      {check(penalty)}
                    </div>
                    <div className="text-xs font-semibold text-center">
                      {moment(penaltyDate)
                        .format("yyyy-MM-DD HH:mm")
                        .toString()}
                    </div>
                  </div>
                );
              },
            },
            {
              label: `Хурд`,
              key: `speed`,
              sort: true,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, speed, speedDate }) => {
                return (
                  <div className="flex flex-col justify-center items-center">
                    <div
                      className={`font-semibold  ${
                        speed ? "text-red-500" : "text-green-500"
                      }`}
                    >
                      {check(speed)}
                    </div>
                    <div className="text-xs font-semibold text-center">
                      {moment(speedDate).format("yyyy-MM-DD HH:mm")}
                    </div>
                  </div>
                );
              },
            },
            {
              label: `Эрэн сурвалжлалт`,
              key: `asap`,
              sort: true,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, asap, asapDate }) => {
                return (
                  <div className="flex flex-col justify-center items-center">
                    <div
                      className={`font-semibold  ${
                        asap ? "text-red-500" : "text-green-500"
                      }`}
                    >
                      {check(asap)}
                    </div>
                    <div className="text-xs font-semibold text-center">
                      {moment(asapDate).format("yyyy-MM-DD HH:mm")}
                    </div>
                  </div>
                );
              },
            },
            {
              label: `Жолоодох эрхгүй`,
              key: `drunk`,
              sort: true,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, drunk, drunkDate }) => {
                return (
                  <div className="flex flex-col justify-center items-center">
                    <div
                      className={`font-semibold  ${
                        drunk ? "text-red-500" : "text-green-500"
                      }`}
                    >
                      {check(drunk)}
                    </div>
                    <div className="text-xs font-semibold text-center">
                      {moment(drunkDate).format("yyyy-MM-DD HH:mm")}
                    </div>
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default index;
